import {Component, Input, OnInit} from '@angular/core';
import { MoviesResponse } from '../models';

@Component({
  selector: 'app-yify-search-results',
  templateUrl: './yify-search-results.component.html',
  styleUrls: ['./yify-search-results.component.scss']
})
export class YifySearchResultsComponent implements OnInit {
  @Input() results: MoviesResponse;

  constructor() { }

  ngOnInit() {
  }

}
