import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() set path(value: string) {
    this.segments = value
        .split("/")
        .map((label, i) => ({i, label}));
  }

  @Output() navigate = new EventEmitter<{path: string}>()

  segments: {i: number, label: string}[] = [];

  constructor() { }

  ngOnInit() {
  }

  click(segmentIndex: number) {
    const path = this.segments.slice(0, segmentIndex + 1).map(s => s.label).join("/");
    this.navigate.emit({path});
  }
}
