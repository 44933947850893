import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api/api.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorMessage: string = null;

  constructor(private readonly api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async login(username: string, password: string) {
    try {
      await this.api.login(username, password);
      await this.router.navigateByUrl("/"); // TODO implement redirect when auto-logged out
    } catch (err) {
      this.errorMessage = `${err.status} - ${err.error.error || err.error}`;
    }
  }
}
