import { Component, OnInit } from '@angular/core';
import {MediaItem} from "../../media-items/media-item";
import {ApiService} from "../../api/api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
  path: string;
  mediaItems: MediaItem[];
  filteredMediaItems: MediaItem[];

  private _searchQuery = '';
  get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
    this.filteredMediaItems = this.mediaItems.filter(i => {
      return value.toLowerCase().split(/\s+/).every(part => i.label.toLowerCase().includes(part));
    })
  }

  constructor(private readonly api: ApiService,
              private router: Router,
              private route: ActivatedRoute) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.navigate(params.p);
    });
  }

  isHome() {
    return this.path === this.route.snapshot.url[0].path;
  }

  homeFolderLabel() {
    return this.route.snapshot.url[0].path.capitalize();
  }

  async navigate(path: string) {
    this.mediaItems = [];
    this.filteredMediaItems = [];
    this.path = (path || this.route.snapshot.url[0].path).replace(/\/+$/g, '');
    this.mediaItems = await this.api.listFolderContents(this.path);
    this.searchQuery = '';
  }

  get pathSegments() {
    return this.path.split("/");
  }

  get lastPathSegment() {
    return this.pathSegments[this.pathSegments.length - 1];
  }

  async setPathUrl(path: string) {
    await this.router.navigate([], { queryParams: { p: path }, queryParamsHandling: 'merge' });
  }

  async watch(path: string) {
    await this.router.navigate(["/watch"], { queryParams: { p: path } });
  }

  async download(path: string) {
    const fileDetails = await this.api.getFileDetails(path);
    const url = this.api.buildAssetUrl(fileDetails.downloadToken);
    window.location.href = url;
  }
}
