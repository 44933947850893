import {Component, Input, OnInit} from '@angular/core';
import {Movie} from "../models";
import {ApiService} from "../../api/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {YifyDetailsDialogComponent} from "../yify-details-dialog/yify-details-dialog.component";

@Component({
  selector: 'app-yify-item',
  templateUrl: './yify-item.component.html',
  styleUrls: ['./yify-item.component.scss']
})
export class YifyItemComponent implements OnInit {

  private _movie: Movie;
  @Input() set movie(value: Movie) {
    this._movie = value;
    this.getCoverImage();
  }
  get movie(): Movie {
    return this._movie;
  }

  coverImage = null;
  coverImagePromise = null;

  constructor(private readonly api: ApiService,
              private readonly sanitizer: DomSanitizer,
              private readonly dialog: MatDialog) { }

  ngOnInit() {
  }

  private getCoverImage() {
    this.coverImagePromise = new Promise(async resolve => {
      const result = await this.api.getYifyMovieDetails(String(this.movie.id));
      this.coverImage = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + result.movie.image_base64);
      resolve(this.coverImage);
    });
  }

  showDetails() {
    const data = {movie: this.movie, image: this.coverImagePromise};
    this.dialog.open(YifyDetailsDialogComponent, {data});
  }

}
