import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiAuthService} from "./api-auth.service";
import {Movie, MediaFile} from "../media-items/media-item";
import { MovieResponse, MoviesResponse } from "../yify/models";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly baseUrl = "https://media.silvestri.io";

  constructor(private readonly http: HttpClient,
              private readonly auth: ApiAuthService) {
  }

  async login(username: string, password: string) {
    const body = { username, password };
    const result: any = await this.http.post(`${this.baseUrl}/api/auth/login`, body).toPromise();
    this.auth.setToken(result.token);
  }

  async getMe() {
    const result: any = await this.http.get(`${this.baseUrl}/api/me`).toPromise();
    return result;
  }

  async updatePassword(password: string) {
    const body = { password };
    await this.http.post(`${this.baseUrl}/api/me/password`, body).toPromise();
  }

  async listFolderContents(path: string) {
    const params = { path };
    const result: any = await this.http.get(`${this.baseUrl}/api/files/folder`, {params}).toPromise();
    return result;
  }

  async getThumbnail(path: string): Promise<string> {
    const params = { path };
    const result: any = await this.http.get(`${this.baseUrl}/api/files/thumbnail`, {params}).toPromise();
    return result.thumbnail;
  }

  async getMovieDetails(path: string): Promise<Movie> {
    const params = { path };
    const result: any = await this.http.get(`${this.baseUrl}/api/files/movie`, {params}).toPromise();
    return result;
  }

  async getFileDetails(path: string): Promise<MediaFile> {
    const params = { path };
    const result: any = await this.http.get(`${this.baseUrl}/api/files/file`, {params}).toPromise();
    return result;
  }

  buildAssetUrl(token: string): string {
    const payload = jwt_decode(token) as any;
    return `${this.baseUrl}/api/assets/${payload.url}?signature=${token}`;
  }

  async searchYify(query: string): Promise<MoviesResponse> {
    const params = { q: query };
    const result: any = await this.http.get(`${this.baseUrl}/api/yify/search`, {params}).toPromise();
    if (result.status !== "ok") {
      throw new Error(result.status_message);
    }
    return result.data;
  }

  async getYifyMovieDetails(id: string): Promise<MovieResponse> {
    const params = { id };
    const result: any = await this.http.get(`${this.baseUrl}/api/yify/movie`, {params}).toPromise();
    if (result.status !== "ok") {
      throw new Error(result.status_message);
    }
    return result.data;
  }

  async getYifyMovieSuggestions(id: string): Promise<MoviesResponse> {
    const params = { id };
    const result: any = await this.http.get(`${this.baseUrl}/api/yify/suggestions`, {params}).toPromise();
    if (result.status !== "ok") {
      throw new Error(result.status_message);
    }
    return result.data;
  }
}
