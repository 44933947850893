import {Component, OnInit} from '@angular/core';
import {Movie} from "../../media-items/media-item";
import {ApiService} from "../../api/api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent implements OnInit {
  movie: Movie;

  constructor(readonly api: ApiService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.navigate(params.p);
    });
  }

  async navigate(path: string) {
    this.movie = await this.api.getMovieDetails(path);
  }

  toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
}
