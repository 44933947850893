import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api/api.service";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  yifyResults = null;
  yifyError = null;

  constructor(private readonly api: ApiService) { }

  ngOnInit() {
  }

  async getMovies(query) {
    try {
      this.yifyResults = await this.api.searchYify(query);
    } catch (err) {
      this.yifyError = err.message;
    }
  }
}
