import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import { IndexComponent } from './routes/index/index.component';
import {RouterModule, Routes} from "@angular/router";
import { Http404Component } from './routes/http404/http404.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ApiTokenInterceptor} from "./api/api-token.interceptor";
import { LoginComponent } from './routes/login/login.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import { ChangePasswordDialogComponent } from './modals/change-password-dialog/change-password-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { BrowseComponent } from './routes/browse/browse.component';
import { ListMediaItemComponent } from './media-items/list-media-item/list-media-item.component';
import {MatRippleModule} from "@angular/material/core";
import { BreadcrumbsComponent } from './media-items/breadcrumbs/breadcrumbs.component';
import {FormsModule} from "@angular/forms";
import { WatchComponent } from './routes/watch/watch.component';
import { YifyItemComponent } from './yify/yify-item/yify-item.component';
import { YifySearchResultsComponent } from './yify/yify-search-results/yify-search-results.component';
import { YifyDetailsDialogComponent } from './yify/yify-details-dialog/yify-details-dialog.component';
import { TvComponent } from './routes/tv/tv.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'login', component: LoginComponent },
  { path: 'movies', component: BrowseComponent },
  { path: 'shows', component: BrowseComponent },
  { path: 'downloads', component: BrowseComponent },
  { path: 'watch', component: WatchComponent },
  { path: 'tv', component: TvComponent },
  { path: '**', component: Http404Component }
];

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    Http404Component,
    LoginComponent,
    ChangePasswordDialogComponent,
    BrowseComponent,
    ListMediaItemComponent,
    BreadcrumbsComponent,
    WatchComponent,
    YifyItemComponent,
    YifySearchResultsComponent,
    YifyDetailsDialogComponent,
    TvComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRippleModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ChangePasswordDialogComponent,
    YifyDetailsDialogComponent
  ]
})
export class AppModule { }
