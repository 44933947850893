import {Component, OnInit} from '@angular/core';
import {ApiService} from "./api/api.service";
import {ApiAuthService} from "./api/api-auth.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordDialogComponent} from "./modals/change-password-dialog/change-password-dialog.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  firstName: string;
  
  constructor(private readonly api: ApiService,
              readonly auth: ApiAuthService,
              private readonly router: Router,
              private readonly dialog: MatDialog) {
  }

  ngOnInit() {
    this.checkLogin();
  }
  
  async checkLogin() {
    try {
      const userInfo = await this.api.getMe();
      this.firstName = userInfo.user.firstName;
      // TODO this does not refresh when user logs in/out without reloading the page
    } catch (e) {
      // interceptor will automatically redirect to login
    }
  }

  logOut() {
    this.auth.clearToken();
    this.router.navigate(["login"]);
  }

  changePassword() {
    this.dialog.open(ChangePasswordDialogComponent);
  }
}
