import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api/api.service";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  errorMessage: string = null;
  inProgress = false;

  constructor(private readonly api: ApiService,
              private readonly dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              private readonly snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  async changePassword(password: string) {
    this.inProgress = true;
    try {
      await this.api.updatePassword(password);
      this.dialogRef.close();
      this.snackBar.open("Password updated", null, {duration: 2000});
    } catch (err) {
      this.errorMessage = `${err.status} - ${err.error.error || err.error}`;
    } finally {
      this.inProgress = false;
    }
  }
}
