import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ApiAuthService} from "./api-auth.service";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {

  constructor(private readonly auth: ApiAuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addToken(request);
    return next
        .handle(request)
        .pipe(catchError(error => this.detectUnauthorized(error)));
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.auth.getToken();

    if (token == null) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private detectUnauthorized(error: HttpErrorResponse) {
    if (!this.isAuthRequest(error)) {
      if (error.status === 401) {
        this.handle401();
      }
    }
    return throwError(error);
  }

  private isAuthRequest(event: HttpErrorResponse): boolean {
    return event.url.includes("/auth");
  }

  private handle401() {
    console.log("REDIRECTING TO LOGIN");
    this.auth.clearToken();
    this.router.navigate(["login"]);
  }
}
