import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {
  private token: string = null;

  constructor() { }

  getToken() {
    if (this.token != null) {
      return this.token;
    }

    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
    }

    return this.token;
  }

  setToken(token) {
    console.log("Saving auth token", token);
    this.token = token;
    localStorage.setItem("token", token);
  }

  hasToken(): boolean {
    return this.token != null;
  }

  clearToken() {
    this.setToken(null);
  }
}
