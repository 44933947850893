import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaItem, Movie} from "../media-item";
import {ApiService} from "../../api/api.service";

@Component({
  selector: 'app-list-media-item',
  templateUrl: './list-media-item.component.html',
  styleUrls: ['./list-media-item.component.scss']
})
export class ListMediaItemComponent implements OnInit {
  private _mediaItem: MediaItem;
  get mediaItem(): MediaItem {
    return this._mediaItem;
  }
  @Input() set mediaItem(value: MediaItem) {
    this._mediaItem = value;
    if (value.type === "movie") {
      if (value.hasThumbnail) {
        this.api.getThumbnail(this.mediaItem.path).then(t => this.thumbnail = t);
      }
    }
  }

  thumbnail: string = null;

  @Output() navigate = new EventEmitter<{path: string}>();
  @Output() watch = new EventEmitter<{path: string}>();
  @Output() download = new EventEmitter<{path: string}>();

  constructor(private readonly api: ApiService) { }

  ngOnInit() {
  }

  click() {
    if (this._mediaItem.type === "directory") {
      this.navigate.emit({path: this._mediaItem.path})
    } else if (this._mediaItem.type === "movie") {
      this.watch.emit({path: this._mediaItem.path})
    } else if (this._mediaItem.type === "file") {
      this.download.emit({path: this._mediaItem.path})
    }
  }
}
